import axios from '../utils/Axios';
import { toastOnError } from '../utils/Utils';
import reduxCrudFactory from '../utils/ReduxCrudFactory';

const factory = reduxCrudFactory({
  axios,
  onError: toastOnError,
  parseIdToInt: true,
  config: {
    answers: {
      route: '/api/wizard/answers/',
      parent: 'choice',
    },
    choices: {
      route: '/api/wizard/choices/',
      parent: 'parent',
      // Create empty child objects list in state for each object fetched from the database
      recursive: true,
    },
    questionnaires: {
      route: '/api/wizard/questionnaires/',
      includeActions: {
        moveQuestionnaire: {
          isAsync: true,
          route: ({ questionnaire }) =>  `/api/wizard/questionnaires/${questionnaire.id}/move/`,
          method: 'put',
          prepare: ({ target, position }) => ({ target, position }),
          onResponse: (objs, { setQuestionnairesList }) => setQuestionnairesList(objs),
        },
      },
    },
    fieldDefinitions: {
      route: '/api/wizard/field_definitions/',
    },
    selectionOptions: {
      route: '/api/wizard/selection_options/',
      parent: 'field_definition',
      includeActions: {
        moveSelectionOption: {
          isAsync: true,
          route: ({ id }) => `/api/wizard/selection_options/${id}/move/`,
          method: 'put',
          prepare: (so, { args: { target, position } }) => ({ target, position }),
          onResponse: (selectionOptions, { setSelectionOptionsList }) => setSelectionOptionsList(selectionOptions),
        },
      },
    },
  },
});

export default factory;

export const actions = factory.actions;
export const mapToProps = factory.mapToProps;
export const use = factory.hooks;
