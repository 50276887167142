import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mapToProps, actions } from '../../redux/Factory';

import {
  DataTable,
} from '../shared/ReactToolbox';
import { getAnswerColumns } from './Answer';

const AnswersList = ({ choice }) => {
  const { getAnswersIsLoading, answersList } = useSelector(s => mapToProps.answers(s, { choice }));
  const { fieldDefinitionsList } = useSelector(mapToProps.fieldDefinitions);
  const { questionnairesList } = useSelector(mapToProps.questionnaires);
  const { id } = useParams();
  
  if (getAnswersIsLoading || !answersList || !questionnairesList || !fieldDefinitionsList) return null;

  const questionnaire = questionnairesList[parseInt(id)];

  return <>
    <DataTable
      style={{ marginTop: '5px', cursor: 'pointer'}}
      showHeader={false}
      columns={getAnswerColumns({ questionnaire, fieldDefinitionsList })}
      data={Object.values(answersList)}
    />
  </>;
}
AnswersList.propTypes = {
}

export default AnswersList;