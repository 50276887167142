
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mapToProps } from '../../redux/Factory';

const QuestionnaireName = () => {
  const { id } = useParams();
  const { questionnaire } = useSelector(s => mapToProps.questionnaires(s, { id: parseInt(id) }))

  return questionnaire && questionnaire.name
}

export default QuestionnaireName;