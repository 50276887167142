import { useSelector } from 'react-redux';
import { mapToProps } from '../../redux/Factory';

 
const Field = ({ field }) => {
  const { selectionOptionsList } = useSelector(
    s => mapToProps.selectionOptions(
      s,
      field ? { field_definition: field.definition } : {})
  );
  if (!field) return null;
  
  switch (field.resourcetype) {
    case 'TextField':
      return field.value;
    case 'SelectionField':
      return (
        selectionOptionsList[field.value]
          ? selectionOptionsList[field.value].name
          : <i>Niet gevonden</i>
      );
    case 'UrlField':
      return  (
        <a
          href={field.value}
          target='_blank'
          rel='noreferrer'
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onClick={e => e.stopPropagation()}
        >
          {field.description || field.value}
        </a>
      );
    default:
      return <i>Niet gevonden</i>;
  } 
}

export default Field;